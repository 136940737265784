import { Footer } from "./Components/Footer/Footer";
import Header from "./Components/Header/Header";
import Menu from "./Components/Menu/Menu";
// import MenuBanner from "./Components/MenuBanner/MenuBanner";
import Navbar from "./Components/Navbar/Navbar";


function App() {
  return (
    <div>
      <Header />
      <Navbar />
      {/* <MenuBanner /> */}
      <Menu />
      <Footer />
    </div>
  );
}

export default App;
